import styles from "./SearchBox.module.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useQueryBundle } from "source/hooks";
import { GetSearchResults } from "$gql/queries/general/GetSearchResults.gen";
import { IexCompanySearchResult } from "$gql/types.gen";
import SearchResults from "./SearchResults";
import PopularBrands from "./PopularBrands/PopularBrands";
import {
  BrandDetailDocument,
  GenericReference,
} from "@tiicker/util/lib/contentful/types";
import LargeSearchIcon from "public/icons/large-search.svg";
import { debounce } from "lodash";
import Button from "components/core/Button/Button";
import classNames from "classnames";

type Props = {
  specifiedSearch?: "Brand" | "Insights" | "Perk";
  brands?: GenericReference<BrandDetailDocument>[] | undefined;
  removePopular?: boolean;
  inputClass?: string;
  containerClass?: string;
  resultClass?: string;
  placeholder?: string;
  searchButton?: boolean;
  searchButtonTillSmall?: boolean;
  brandResultClickOverride?: (tickerSymbol: string, hasPerks: boolean) => void;
};

const SearchBox = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>();
  const [iexResult, setIexResult] = useState<IexCompanySearchResult[]>([]);
  const [insightResult, setInsightResult] = useState<
    { title: string; slug: string }[]
  >([]);
  const [perkResult, setPerkResult] = useState<
    { title: string; tickerSymbol: string }[]
  >([]);

  const search = useQueryBundle(GetSearchResults, {
    skip: true,
  });

  const runSearch = useCallback((term: string) => {
    search.refetch({ searchTerm: term }).then((result) => {
      if (
        result.data.search.iex &&
        result.data.search.iex.length > 0 &&
        props.specifiedSearch === "Brand"
      ) {
        setIexResult(result.data.search.iex);
      }
      if (
        result.data.search.insights &&
        result.data.search.insights.length > 0 &&
        props.specifiedSearch === "Insights"
      ) {
        setInsightResult(result.data.search.insights);
      }
      if (
        result.data.search.perks &&
        result.data.search.perks.length > 0 &&
        props.specifiedSearch === "Perk"
      ) {
        setPerkResult(result.data.search.perks);
      }
    });
  }, []);

  const debouncedSearch = useCallback(
    debounce((term: string) => runSearch(term), 800),
    []
  );

  const onBlur = () => {
    setTimeout(() => {
      setSearchTerm("");
    }, 250);
  };

  useEffect(() => {
    if (searchTerm) {
      debouncedSearch(searchTerm);
    } else {
      setIexResult([]);
      setInsightResult([]);
      setPerkResult([]);
    }
  }, [searchTerm]);

  return (
    <div className={styles.SearchBox__inputAndResultContainer}>
      <div className={styles.SearchBox__inputContainer}>
        <div className={styles.SearchBox__inputIconContainer}>
          <div className={props.containerClass ?? styles.inlineInputContainer}>
            <input
              className={classNames(
                styles.SearchBox__input,
                props.inputClass ? props.inputClass : undefined
              )}
              placeholder={
                props.placeholder ?? "Search for your favorite brands"
              }
              onBlur={onBlur}
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
            {props.searchButton ? (
              <Button arrow>Search</Button>
            ) : props.searchButtonTillSmall ? (
              <>
                <div className={styles.showTillSmall}>
                  <Button arrow>Search</Button>
                </div>
                <div
                  className={classNames(
                    styles.SearchBox__iconContainer,
                    styles.hideTillSmall
                  )}
                >
                  <LargeSearchIcon className={styles.icon} />
                </div>
              </>
            ) : (
              <div className={styles.SearchBox__iconContainer}>
                <LargeSearchIcon className={styles.icon} />
              </div>
            )}
          </div>
          <SearchResults
            brandResults={iexResult}
            insightResults={insightResult}
            perkResults={perkResult}
            containerClass={props.resultClass}
            brandResultClickOverride={props.brandResultClickOverride}
            searchTerm={searchTerm}
          />
        </div>
      </div>
      {!props.removePopular && (
        <div className={styles.popBrandsContainer}>
          <PopularBrands brands={props.brands} />
        </div>
      )}
    </div>
  );
};

export default SearchBox;
