import React from "react";
import styles from "./ColorWrapper.module.scss";

type Props = {
  children: React.ReactNode;
  displayOptions?: {
    marginBottom?: number;
  };
};

const ColorWrapper = (props: Props) => {
  return (
    <div
      style={{
        marginBottom: props.displayOptions?.marginBottom || 0,
      }}
      className={styles.container}
    >
      {props.children}
    </div>
  );
};

export default ColorWrapper;
