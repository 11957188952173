import React from "react";
import Button from "components/core/Button/Button";
import { useAuth } from "source/hooks";
import styles from "./StartEarningPerks.module.scss";
import { useUser } from "components/UserContext/UserContext";

interface Props {
  title: string;
  description: string;
}

const StartEarningPerks = (props: Props) => {
  const userContext = useUser();
  const auth = useAuth();

  if (userContext.state.userId) {
    return <></>;
  }

  return (
    <div className={styles.StartEarningPerks}>
      <div className="container">
        <div className="row">
          <div className="col-md-1" />

          <div className="col-md-10">
            <div className={styles.StartEarningPerks__content}>
              <div className={styles.StartEarningPerks__headerContainer}>
                <h2
                  dangerouslySetInnerHTML={{ __html: props.title }}
                  className={styles.StartEarningPerks__header}
                ></h2>
                <p className={styles.StartEarningPerks__subHeader}>
                  {props.description}
                </p>
              </div>
              <Button onClick={() => auth.smsSignUp()} arrow>
                Join Now
              </Button>
            </div>
          </div>
          <div className="col-md-1" />
        </div>
      </div>
    </div>
  );
};

export default StartEarningPerks;
