import React from "react";
import Button from "components/core/Button/Button";
import { useAuth } from "source/hooks";
import styles from "./StartEarningPerksMadi.module.scss";
import { useUser } from "components/UserContext/UserContext";
import Container from "components/core/Layout/Container/Container";
import Markdown from "components/Markdown/Markdown";
import { PageSection } from "@tiicker/util/lib/contentful/types";
import { Entry } from "contentful";
import { getImageReference } from "@tiicker/util/lib/contentful/helpers";
import StartEarningPerks from "components/archived_components/StartEarningPerks/StartEarningPerks";

interface Props {
  page: Entry<PageSection> | undefined;
  includes?: any;
}

const StartEarningPerksMadi = (props: Props) => {
  const userContext = useUser();
  const auth = useAuth();

  const madiImage = props.page
    ? getImageReference(props.page.fields.image, props.includes)
    : undefined;

  const defaultTitle = "Start earning perks <span>today</span>";
  const defaultDescription =
    "TiiCKER was created for fan-first, brand-first public companies—with exclusive perks served-up weekly to shareholders. Own stock? Connect your brokerage account to view perks waiting for you right now!";

  if (userContext.state.userId) {
    return <></>;
  }

  if (!props.page) {
    return (
      <StartEarningPerks
        title={defaultTitle}
        description={defaultDescription}
      />
    );
  }

  return (
    <StartEarningPerks
      title={props.page.fields.displayTitle || defaultTitle}
      description={props.page.fields.description || defaultDescription}
    />
  );

  // return (
  //   <div className={styles.parentContainer}>
  //     <div className={styles.startEarningPerks}>
  //       <Container>
  //         <div className={styles.startEarningPerks__container}>
  //           <div className={styles.startEarningPerks__content}>
  //             <Markdown
  //               className={styles.startEarningPerks__header}
  //               markdownText={
  //                 props.page.fields.title ||
  //                 "Start earning perks <span>today</span>"
  //               }
  //             />
  //             <p>
  //               {props.page.fields.description ||
  //                 `TiiCKER was created for fan-first, brand-first public companies—with exclusive perks
  //           served-up weekly to shareholders. Own stock? Connect your brokerage account to view perks
  //           waiting for you right now!`}
  //             </p>
  //             {!userContext.state.userId && (
  //               <Button onClick={() => auth.smsSignUp()} arrow>
  //                 Join Now
  //               </Button>
  //             )}
  //           </div>
  //           <div className={styles.startEarningPerks__madiImageContainer}>
  //             <img
  //               src={madiImage ? madiImage.fields.file.url : undefined}
  //               className={styles.startEarningPerks__madiImage}
  //             />
  //           </div>
  //         </div>
  //       </Container>
  //     </div>
  //   </div>
  // );
};

export default StartEarningPerksMadi;
