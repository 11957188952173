import { GetMonthlyPopularBrandSearchTerms } from "$gql/queries/general/GetMonthlyPopularBrandSearchTerms.gen";
import {
  BrandDetailDocument,
  GenericReference,
} from "@tiicker/util/lib/contentful/types";
import Link from "next/link";
import React from "react";
import { useQueryBundle } from "source/hooks";
import styles from "./PopularBrands.module.scss";

type Props = {
  brands?: GenericReference<BrandDetailDocument>[] | undefined;
};

const brandLink = (name: string, ticker: string) => {
  return (
    <Link key={`brand-link-${name}-${ticker}`} href={`/${ticker}`}>
      <a>
        <div className={styles.brandName}>{name}</div>
      </a>
    </Link>
  );
};

const PopularBrands = (props: Props) => {
  const results = useQueryBundle(GetMonthlyPopularBrandSearchTerms, {
    skip: (props.brands || []).length >= 6,
  });

  const contentfulBrandsDestructured =
    props.brands && props.brands.length > 0
      ? props.brands.map((brand) => {
          return {
            name: (brand as any).fields.brandName,
            tickerSymbol: (brand as any).fields.tickerSymbol,
          };
        })
      : [];

  const contentfulBrands = contentfulBrandsDestructured.map((brand) =>
    brandLink(brand.name, brand.tickerSymbol)
  );

  // Filter out contentful brands to make sure we dont have duplicates
  const popularSearches =
    results.state === "DONE"
      ? results.data.getMonthlyPopularBrandSearchTerms
          .filter(
            (x) =>
              !contentfulBrandsDestructured.some(
                (c) => c.tickerSymbol === x.tickerSymbol
              )
          )
          .map((x) => brandLink(x.name, x.tickerSymbol))
      : [];

  const popularBrandsToShow = contentfulBrands.concat(popularSearches);

  return (
    <div className={styles.container}>
      <p className={styles.popular}>Popular</p>
      {popularBrandsToShow}
    </div>
  );
};

export default PopularBrands;
