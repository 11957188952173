import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, SwiperOptions, Autoplay } from "swiper";
import styles from "./SwiperCarousel.module.scss";
import { AutoplayOptions, PaginationOptions } from "swiper/types";
import CircledLeftArrowIcon from "public/icons/circled-left-arrow.svg";
import CircledRightArrowIcon from "public/icons/circled-right-arrow.svg";

import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css";
import Container from "components/core/Layout/Container/Container";
import classnames from "classnames";
import classNames from "classnames";

type Props = {
  slides: React.ReactNode[];
  spaceBetween?: number;
  slidesPerView?: number;
  slidesPerGroup?: number;
  pagination?: boolean;
  navigation?: boolean;
  paginationOptions?: PaginationOptions;
  breakPoints?: { [width: number]: SwiperOptions };
  autoplay?: AutoplayOptions;
  slideClass?: string;
  swiperClass?: string;
  loop?: boolean;
  openSide?: "right" | "left" | "right-till-small" | "both";
  centeredSlides?: boolean;
  navigationLayout?: "integrated" | "bottom" | "partner";
  bulletsClass?: string;
  dotLabels?: string[];
  dotLabelClass?: string;
};

const SwiperCarousel = (props: Props) => {
  return (
    <>
      <Container openSide={props.openSide}>
        <div
          className={
            props.navigationLayout === "integrated"
              ? styles.sliderContainer_Integrated
              : props.navigationLayout === "partner"
              ? styles.sliderContainer_Partner
              : styles.sliderContainer
          }
        >
          {props.navigation && props.navigationLayout === "integrated" && (
            <div
              className={classnames(
                styles.integrated__arrow,
                styles.arrows__prev
              )}
            >
              <CircledLeftArrowIcon />
            </div>
          )}
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            autoplay={props.autoplay}
            watchOverflow={true}
            navigation={{
              prevEl: `.${styles.arrows__prev}`,
              nextEl: `.${styles.arrows__next}`,
              disabledClass: styles.arrows__disabled,
            }}
            pagination={{
              el: `.${styles.bullets}`,
              clickable: true,
              bulletClass: props.navigationLayout
                ? styles.page_Partner
                : styles.page,
              bulletActiveClass: styles.page__current,
              horizontalClass:
                props.navigationLayout === "integrated"
                  ? styles.pageContainer_Integrated
                  : styles.pageContainer,
              ...props.paginationOptions,
              renderBullet: props.dotLabels
                ? function (index, className) {
                    if (props.dotLabels) {
                      return (
                        '<span class="' +
                        classnames(className, props.dotLabelClass) +
                        '">' +
                        props.dotLabels[index] +
                        "</span>"
                      );
                    }
                    return undefined;
                  }
                : undefined,
            }}
            breakpoints={props.breakPoints}
            spaceBetween={props.spaceBetween}
            slidesPerView={props.slidesPerView ?? "auto"}
            slidesPerGroup={props.slidesPerGroup ?? 1}
            className={classNames(styles.swiper, props.swiperClass)}
            loop={props.loop}
            centeredSlides={props.centeredSlides}
          >
            {props.slides.map((slide, i) => (
              <SwiperSlide key={`slide-${i}`} className={props.slideClass}>
                {slide}
              </SwiperSlide>
            ))}
          </Swiper>
          {props.navigation && props.navigationLayout === "integrated" && (
            <div
              className={classnames(
                styles.integrated__arrow,
                styles.arrows__next
              )}
            >
              <CircledRightArrowIcon />
            </div>
          )}
        </div>
      </Container>
      {(props.pagination || props.navigation) && (
        <div className="container">
          {props.pagination && props.navigationLayout === "integrated" && (
            <div className={styles.integrated_navigation}>
              <div className={styles.bullets} />
            </div>
          )}
          <div
            className={classnames(
              props.navigationLayout === "partner"
                ? styles.buttonContainer_Partner
                : styles.buttonContainer,
              props.navigationLayout === "integrated"
                ? styles.integrated__mobileNavigation
                : undefined
            )}
          >
            {props.pagination && props.navigationLayout !== "integrated" && (
              <div className={classnames(styles.bullets, props.bulletsClass)} />
            )}
            {props.navigation && (
              <div className={styles.arrows}>
                <CircledLeftArrowIcon className={styles.arrows__prev} />
                <CircledRightArrowIcon className={styles.arrows__next} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SwiperCarousel;
