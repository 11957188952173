import { GenericMarketingPage } from "@tiicker/util/lib/contentful/types";
import { getImageReference } from "@tiicker/util/lib/contentful/helpers";
import React from "react";
import MetaTags from "./MetaTags";
import MetaImage from "./MetaImage";

interface Props {
  pageContent: GenericMarketingPage;
}

const GenericMetaTags = (props: Props) => {
  const metaImage = getImageReference(props.pageContent.metaImage);

  return (
    <>
      <MetaTags
        title={props.pageContent.metaTitle}
        description={props.pageContent.metaDescription}
      />

      {metaImage && (
        <MetaImage
          image={metaImage.fields.file.url}
          imageAlt={props.pageContent.metaDescription}
        />
      )}
    </>
  );
};

export default GenericMetaTags;
